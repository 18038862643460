<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="../../../assets/images/logo/logo_new.png"
            style="height: 30px;"
          >
        </b-link>

        <b-card-title class="mb-1">
          Welcome to GMS Volunteer Management! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your GMS Church Account
        </b-card-text>

        <!-- redirect button -->
        <b-button
          variant="primary"
          block
          @click="login"
        >
          Sign in
        </b-button>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import jwt from '@/auth/jwt/jwt'
// import { stringify } from 'query-string'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  methods: {
    login() {
      // Show loading
      document.getElementById('loading-bg').style.display = 'block'
      document.getElementById('app').style.display = 'none'

      // Redirect to oauth
      this.$auth.authenticate('mygms').then(result => {
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/authenticate?token=${result.data.code}`)
          .then(account => {
            // Set jwt for token
            jwt.setToken(account.data.jwtToken)

            // User data
            const { user, isFirstRegistration } = account.data

            // Set cookie for user data
            const encryptedCookies = CryptoJS.AES.encrypt(JSON.stringify(user), process.env.VUE_APP_COOKIE_SECRET).toString()
            sessionStorage.setItem('userData', encryptedCookies, { expires: new Date(new Date().getTime() + 60 * 60 * 1000) })

            // If first registration
            if (isFirstRegistration) {
                return this.$router.replace({ name: 'misc-not-authorized' })
            }

            // Update ability
            this.$ability.update(user)

            // hide loading
            document.getElementById('loading-bg').style.display = 'none'
            document.getElementById('app').style.display = 'block'
            
            // Redirect
            // return this.$router.replace('/dashboard')
            return this.$router.replace('/servolution/events')
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

.brand-logo img {
  // width: 100px;
  height: 87px;
}
</style>
